import React, {
  useState,
  useEffect,
} from 'react';
import Modal from '../../components/Modal';
import UserForm from '../../components/UserForm';
import Select from '../../components/Select';
import AnonymousLink from '../../components/AnonymousLink';
import filterObjectLibrary from '../../libraries/filterObject';

/**
 * Guest modal
 * @param {Object} [props]
 * @param {Object} [props.user] - User object
 * @param {Object} [props.anonymousLinkData] - Data for AnonymousLink component
 * @param {boolean} [props.isOpen] - Show/hide modal
 * @param {boolean} [props.isLoading] - Spinner activ on button after request is sent
 * @param {boolean} [props.privateFields] - Mark private fileds (diffrent fileds style)
 * @param {string} [props.title] - Title text
 * @param {'small' | 'default' | 'big'} [props.size] - Modal width size
 * @param {Function} [props.onConfirm] - Callback method when form us submited
 * @param {Function} [props.onRequestClose] - Callback method when modal should be closed
 * @param {Function} [props.onCreateLink] - Callback method when anonymous link is created
 * @param {Array} [props.contacts] - List of user's contacts
 * @param {boolen} [props.isError] - Error happened on request
 * @param {Array} [props.errorMsg] - Error messages on request returned with error
 * @param {boolen} [props.hasPassAssigningCheckbox] - checkbox on form for assigning ticket
 * @component
 */
export default ({
  user = {},
  anonymousLinkData = {},
  isOpen = false,
  isLoading = false,
  privateFields = false,
  title = '',
  size = 'default',
  onConfirm = () => {},
  onRequestClose = () => {},
  onCreateLink = () => {},
  contacts = [],
  isError = false,
  errorMsg = null,
  hasPassAssigningCheckbox
}) => {
  const [assignedUser, setAssignedUser] = useState(user);
  const [hide, setHide] = useState(false);

  /**
   * These useEffects are hacks to rerender UserForm component
   */
  useEffect(() => { setHide(true) }, [assignedUser]);
  useEffect(() => { setHide(false) }, [hide]);

  const contactsForSelection = [];

  contacts && contacts.forEach(contact => {
    if (contact.first_name && contact.last_name) {
      contactsForSelection.push({"name": `${contact.last_name} ${contact.first_name}`, "value": contact.id})
      return
    } else if (contact.first_name) {
      contactsForSelection.push({"name": `${contact.first_name}`, "value": contact.id})
      return
    } else if (contact.last_name) {
      contactsForSelection.push({"name": `${contact.last_name}`, "value": contact.id})
      return
    } else {
      contactsForSelection.push({"name": `${contact.phone}`, "value": contact.id})
    }
  });

  const selectContactHandler = event => setAssignedUser(contacts.find(contact => contact.id === parseInt(event.target.value)));

  const handleRequestClose = () => {
    setAssignedUser({});

    onRequestClose();
  };

  const handleCreateLink = data => {
    setHide(true);

    onCreateLink(data);
  };

  // Only return data from list (array)
  const filterUserData = userData => filterObjectLibrary(userData, [
    // 'birthday',
    // 'city',
    // 'country',
    'first_name',
    'last_name',
    'phone',
    // 'street',
    // 'zip',
    // 'email',
  ]);

  return(
    <Modal
      isOpen={isOpen}
      title={title}
      size={size}
      onRequestClose={handleRequestClose}
      >
      {!!Object.keys(anonymousLinkData).length && (
        <AnonymousLink
          gameId={anonymousLinkData.gameId}
          passId={anonymousLinkData.passId}
          link={anonymousLinkData.link}
          disabled={anonymousLinkData.disabled}
          onCreateLink={handleCreateLink}
        />
      )}

      {!anonymousLinkData?.link && (
        <>
          {/* {privateFields && (
            <div className="help-info">
              <Paragraph theme="light">
              Ihr Gast hat die privaten Daten bereits angepasst. Bitte ändern Sie diese nur in Absprache mit Ihrem Gast, um falsche Angaben zu vermeiden!
              </Paragraph>
            </div>
          )} */}

          <Select
            label="Wählen Sie aus Ihren Kontakten aus"
            options={contactsForSelection}
            labelInOption="Kontakte"
            theme="light"
            onOptionChange={selectContactHandler}
          />

          {!hide && (
            <UserForm
              hasAddress={false}
              hasZip={false}
              hasCity={false}
              hasCountry={false}
              hasBirthday={false}
              disabledScrollToTop
              user={assignedUser ? filterUserData(assignedUser) : {}}
              theme="light"
              mandatory={["phone"]}
              // privateFields={privateFields ? ['street', 'zip', 'city', 'birthday', 'email'] : []}
              isLoading={isLoading}
              confirmLabel="Speichern"
              cancelLabel="Abbrechen"
              onConfirm={onConfirm}
              onCancel={onRequestClose}
              isError={isError}
              errorMsg={errorMsg}
              hasPassAssigningCheckbox={hasPassAssigningCheckbox}
            />
          )}
        </>
      )}
    </Modal>
  );
};
